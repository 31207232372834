import styled from 'styled-components';

export const HeaderPage = styled.section`
  padding: 32px;
  text-align: center;
`;

export const TitlePage = styled.h1`
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  color: #810e56;
`;

export const SubTitlePage = styled.small`
  font-weight: bold;
  font-size: 16px;
  color: #6a7583;
`;

export const Container = styled.div`
  width: 100vw;
  padding: 32px 0;
`;

export const Title = styled.h2`
  text-align: center;
  font-size: 24px;
`;

export const CardForm = styled.section`
  width: 100%;
  padding: 16px;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 6px;
  margin-bottom: 16px;
`;

export const BtnWizzard = styled.button`
  width: 100%;
  background: transparent;
  padding: 8px 0;
  border: none;
  font-weight: bold;
  font-size: 12px;
  color: #b2b7bc;

  ${({ active }) =>
    active &&
    `
    border-bottom: 2px solid #810e56;
    color: #810e56
  `}
`;

export const WizzardBody = styled.section`
  display: flex;
  flex-direction: column;
  /* padding: 32px 0; */
  min-height: 200px;
`;

export const WizzardHeader = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  h6 {
    font-weight: bold;
    font-size: 16px;
    color: #777;
  }
`;

export const BtnHeader = styled.button`
  padding: 5px 16px;
  border: none;
  background: #ffbd21;
  color: #fff;
  border-bottom: 3px solid #f7b008;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
`;

export const BtnHeaderCancel = styled.button`
  background: transparent;
  border: 2px solid #f2f2f2;
  border-radius: 4px;
  font-size: 10px;
  padding: 5px 10px;
  color: #666;
  font-family: 'Century Gothic Bold', Helvetica, Verdana, sans-serif;
`;

export const WizzardFooter = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 48px 0;
`;

export const CardResume = styled.section``;

export const LabelField = styled.label`
  text-transform: uppercase;
  font-size: 11px;
  font-family: 'Century Gothic Bold', Helvetica, Verdana, sans-serif;
  color: #999;
`;

export const Legend = styled.legend`
  font-size: 0.7rem;
  text-transform: uppercase;
  padding: 16px 0;
  font-family: 'Century Gothic', Helvetica, Verdana, sans-serif;
  font-weight: bold;
  color: #810e56;
  border-bottom: 1px solid #f2f2f2;
`;

export const InputField = styled.input`
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
  border: none;
  background: #fafafa;
  border-bottom: 1px solid #dedede;
  border-radius: 4px;
  font-size: 0.8rem;
  font-family: 'Century Gothic', Helvetica, Verdana, sans-serif;
  text-indent: 15px;
  color: #454545;
`;

export const StepNextBtn = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 16px;
  border: none;
  background: #ffbd21;
  color: #fff;
  border-bottom: 3px solid #f7b008;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
`;

export const StepNextBtnTxt = styled.span`
  margin-right: 5px;
`;

export const StepPreviousBtn = styled.button`
  text-transform: uppercase;
  font-size: 12px;
  color: #999;
  border: none;
  background: transparent;
  font-family: 'Century Gothic Bold', Helvetica, Verdana, sans-serif;
`;

export const ResumeTitle = styled.h4`
  font-size: 16px;
  font-family: 'Century Gothic Bold', Helvetica, Verdana, sans-serif;
  color: #333;
`;

export const ResumeItem = styled.article`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid #f2f2f2;
`;

export const ResumeItemDashed = styled.article`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 2px dotted #dedede;
`;

export const ResumeItemTotal = styled.article`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
`;

export const ResumeItemTitle = styled.span`
  font-size: 12px;
  font-family: 'Century Gothic Bold', Helvetica, Verdana, sans-serif;
  color: #666;
`;

export const ResumeItemSubTitle = styled.span`
  font-size: 12px;
  font-family: 'Century Gothic Bold', Helvetica, Verdana, sans-serif;
  color: #666;
`;

export const ResumeItemSubTotal = styled.span`
  font-size: 16px;
  font-family: 'Century Gothic Bold', Helvetica, Verdana, sans-serif;
  color: #f7b008;
`;

export const WizzardContent = styled.section`
  width: 100%;
  min-height: 50px;
  .not-required {
    opacity: 0.5;
  }

  .withoutMinHeight {
    min-height: none;
  }
`;

export const PaymentSelectionOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 250px;
  margin-bottom: 32px;
  padding: 32px;
  border-radius: 12px;
  transition: all 0.3s ease-in-out;
  background: #fafafa;

  h6 {
    font-size: 16px;
    font-weight: bold;
    color: #810e56;
  }

  p {
    text-align: center;
    color: #666;
    font-size: 12px;
    font-weight: bold;
    font-family: 'Century Gothic Bold', Helvetica;

    a {
      padding: 0 8px;
    }
  }

  span {
    text-align: center;
    font-size: 12px;
  }

  button {
    background: transparent;
    border: 1px solid #f7b008;
    padding: 5px 10px;
    border-radius: 4px;
    color: #f7b008;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 16px;
  }

  &.selected {
    border: 2px dashed #810e56;
  }

  &:hover {
    background: #f2f2f2;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
`;

export const ReviewLabel = styled.span``;

export const ReviewInfo = styled.span``;

export const TermContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  margin: 16px 0;
  background: #fafafa;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  label {
    font-size: 12px;
    color: #666;
    &.label-error {
      color: red !important;
    }
    input {
      margin-right: 16px;
    }
  }
`;

export const EmptySection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 32px 0;

  h6 {
    font-weight: bold;
    font-size: 0.8rem;
  }
`;

export const PdfContainer = styled.div`
  canvas {
    width: 100% !important;
  }
`;
export const TooltipElement = styled.div`
  background: white;
  padding: 10px;
  border: 2px solid #747474;
  color: darkred;
`;
