import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Row, Col, Modal } from 'react-bootstrap';

import FormFieldError from './../../../components/FormFieldError';
import FisicalContext from '../../../context/fisical';
import ArrowImage from '../../../assets/icons/arrow.svg';

import {
  WizzardBody,
  WizzardHeader,
  WizzardContent,
  CardForm,
  WizzardFooter,
  LabelField,
  InputField,
  StepNextBtn,
  StepPreviousBtn,
  StepNextBtnTxt,
} from '../styles';
import InputMask from 'react-input-mask';
import api from '../../../services/api';
import FormError from '../../../components/FormError';
import QuestionMarkComponent from '../../../assets/svg/QuestionMarkComponent';

export default function FinancialResponsibleStep() {
  const { step, changeStep, updateData, formData } = useContext(FisicalContext);
  const { register, handleSubmit, watch, errors, getValues } = useForm();

  const [name, setName] = useState(formData.finantialResponsible.name);
  const [phone, setPhone] = useState(formData.finantialResponsible.phone);
  const [email, setEmail] = useState(formData.finantialResponsible.email);
  const [cpf, setCpf] = useState(formData.finantialResponsible.social_number);
  const [cep, setCep] = useState(formData.finantialResponsible.postalCode);
  const [mask, setMask] = useState('(99) 9999-9999');
  const [neightborhood, setNeightborhood] = useState(
    formData.finantialResponsible.neightborhood
  );
  const [complement, setComplement] = useState(
    formData.finantialResponsible.complement
  );
  const [address, setAddress] = useState(formData.finantialResponsible.address);
  const [city, setCity] = useState(formData.finantialResponsible.city);
  const [sex, setSex] = useState(formData.finantialResponsible.sex);
  const [numero, setNumero] = useState(formData.finantialResponsible.number);
  const [parentName, setParentName] = useState(
    formData.finantialResponsible.parentName
  );
  const [civilState, setCivilState] = useState(
    formData.finantialResponsible.civilState
  );
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [born, setBorn] = useState(formData.finantialResponsible.born);
  const [openHelpModal, setOpenHelpModal] = useState(false);

  const style = {
    width: '100%',
    height: 40,
    marginBottom: 16,
    border: 0,
    backgroundColor: '#fafafa',
    borderBottom: '1px solid #dedede',
    borderRadius: 4,
    fontSize: '0.8rem',
    fontFamily: 'Helvetica, Verdana, sans-serif',
    textIndent: 15,
    color: '#999',
  };

  const onSubmit = (data) => {
    const values = getValues();
    updateData({
      finantialResponsible: { ...data, documents: [file1, file2] },
    });
    changeStep(4);
  };

  useEffect(() => {
    populateForm(formData);
    window.scrollTo(0, 0);
  }, []);

  const populateForm = (data) => {
    setName(data.finantialResponsible.name);
    setEmail(data.finantialResponsible.email);
    setPhone(data.finantialResponsible.phone);
    setCpf(data.finantialResponsible.social_number);
    setSex(data.finantialResponsible.sex);
    setCivilState(data.finantialResponsible.civilState);
    setBorn(data.finantialResponsible.born);
    setParentName(data.finantialResponsible.parentName);
    setComplement(data.finantialResponsible.complement);
    setCep(data.finantialResponsible.postalCode);
    setAddress(data.finantialResponsible.address);
    setNeightborhood(data.finantialResponsible.neightborhood);
    setCity(data.finantialResponsible.city);
    setNumero(data.finantialResponsible.number);
    setFile1(
      data.finantialResponsible.documents &&
        data.finantialResponsible.documents[0]
        ? data.finantialResponsible.documents[0]
        : null
    );
    setFile2(
      data.finantialResponsible.documents &&
      data.finantialResponsible.documents[1]
        ? data.finantialResponsible.documents[1]
        : null
    );
    setFile3(
      data.finantialResponsible.documents &&
      data.finantialResponsible.documents[2]
        ? data.finantialResponsible.documents[2]
        : null
    );
  };

  const getAddress = async (event) => {
    if (!cep) return
    const res = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
    let test = await res.json();
    setAddress(`${test.logradouro || ''}`);
    setNeightborhood(`${test.bairro || ''}`);
    setCity(`${test.localidade || ''}`);
  };

  const handleDocumentUpload = async (eid, t) => {
    const form = new FormData();
    const docfile = document.querySelector(`#documentInput${eid}`);
    form.append('document', docfile.files[0]);
    api
      .post('/documentUpload', form, {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      })
      .then((response) => {
        switch (eid) {
          case 1:
            setFile1({ title: t, path: response.data.filePath });
            break;
          case 2:
            setFile2({ title: t, path: response.data.filePath });
            break;
          case 3:
            setFile3({ title: t, path: response.data.filePath });
            break;
        }
      });
  };

  const checkBorn = (value) => {
    if (value && value.indexOf('_') === -1) {
      const from = born.split('/');
      const birthdateTimeStamp = new Date(from[2], from[1] - 1, from[0]);
      const cur = new Date();
      const diff = cur - birthdateTimeStamp;
      const currentAge = Math.floor(diff / 31557600000);
      if (currentAge >= 18) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <WizzardBody>
      <CardForm>
        <WizzardHeader>
          Responsável Financeiro{' '}
          <QuestionMarkComponent
            onClick={() => setOpenHelpModal(true)}
            style={{
              width: '14px',
              height: '14px',
              marginTop: '-5px',
              cursor: 'pointer',
              opacity: '0.5',
            }}
          />
        </WizzardHeader>
        <WizzardContent>
          <form>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <LabelField>Nome</LabelField>
                  <InputField
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    ref={register({ required: true, minLength: 5 })}
                  />
                  <FormFieldError field={errors.name} />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <LabelField>Email</LabelField>
                  <InputField
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    ref={register({
                      required: true,
                      pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                    })}
                  />
                  <FormFieldError field={errors.email} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <LabelField>Telefone</LabelField>
                  <InputField
                    type="hidden"
                    name="phone"
                    style={style}
                    value={phone}
                    ref={register({
                      required: true,
                      minLength: 11,
                    })}
                  />
                  <InputMask
                    type="text"
                    style={style}
                    name="phone"
                    mask="(99) 99999-9999"
                    value={phone}
                    onChange={(e) => {
                      if (e.target.value[5] === '9') {
                        setMask('(99) 99999-9999');
                      } else {
                        setMask('(99) 9999-9999');
                      }
                      setPhone(e.target.value);
                    }}
                    ref={register({
                      required: false,
                      minLength: 11,
                    })}
                  />
                  {errors.phone?.type === 'required' && (
                    <FormError msg="Este campo é obrigatório" />
                  )}
                  {errors.phone?.type === 'minLength' && (
                    <FormError msg="Insira um telefone válido" />
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <LabelField>CPF</LabelField>
                  <InputField
                    type="hidden"
                    name="social_number"
                    value={cpf}
                    ref={register({
                      required: true,
                      pattern: /^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}|\d{3}\.?\d{3}\.?\d{3}-?\d{2})$/,
                    })}
                  />
                  <InputMask
                    type="text"
                    mask="999.999.999-99"
                    alwaysShowMask={false}
                    style={style}
                    name="social_number"
                    onChange={(e) => setCpf(e.target.value)}
                    value={cpf}
                    ref={register({
                      required: true,
                      pattern: /^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}|\d{3}\.?\d{3}\.?\d{3}-?\d{2})$/,
                    })}
                  />
                  {errors.social_number?.type === 'required' && (
                    <FormError msg="Este campo é obrigatório" />
                  )}
                  {errors.social_number?.type === 'pattern' && (
                    <FormError msg="Insira um CPF válido" />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <LabelField>Sexo</LabelField>
                  <InputField
                    as="select"
                    name="sex"
                    value={sex}
                    onChange={(e) => setSex(e.target.value)}
                    ref={register({ required: true })}>
                    <option value="">Selecione</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Feminino">Feminino</option>
                  </InputField>
                  <FormFieldError field={errors.sex} />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <LabelField>Estado Civil</LabelField>
                  <InputField
                    as="select"
                    name="civilState"
                    value={civilState}
                    onChange={(e) => setCivilState(e.target.value)}
                    ref={register({ required: true })}>
                    <option value="">Selecione</option>
                    <option value="Solteiro(a)">Solteiro(a)</option>
                    <option value="Casado(a)">Casado(a)</option>
                    <option value="Separado(a)">Separado(a)</option>
                    <option value="Viuvo(a)">Viuvo(a)</option>
                    <option value="Desquitado(a)">Desquitado(a)</option>
                  </InputField>
                  <FormFieldError field={errors.civilState} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <LabelField>Data de Nascimento</LabelField>
                  <InputField
                    type="hidden"
                    name="born"
                    value={born}
                    ref={register({
                      required: true,
                      validate: (value) => checkBorn(value),
                      pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
                    })}
                  />
                  <InputMask
                    type="text"
                    style={style}
                    mask="99/99/9999"
                    alwaysShowMask={false}
                    name="born"
                    onChange={(e) => setBorn(e.target.value)}
                    value={born}
                  />
                  {errors.born?.type === 'required' && (
                    <FormError msg="Este campo é obrigatório" />
                  )}
                  {errors.born?.type === 'pattern' && (
                    <FormError msg="Insira uma data válida" />
                  )}
                  {errors.born?.type === 'validate' && (
                    <FormError msg="O responsável financeiro deve ser maior de idade " />
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <LabelField>Nome da Mãe</LabelField>
                  <InputField
                    name="parentName"
                    value={parentName}
                    onChange={(e) => setParentName(e.target.value)}
                    ref={register({ required: true })}
                  />
                  <FormFieldError field={errors.parentName} />
                </Form.Group>
              </Col>
            </Row>
            <fieldset>
              <legend>Endereço</legend>
              <Row>
                <Col xs={12} md={3}>
                  <Form.Group>
                    <LabelField>CEP</LabelField>
                    <InputField
                      name="postalCode"
                      maxLength={8}
                      value={cep}
                      onChange={(e) => setCep(e.target.value)}
                      onBlur={getAddress}
                      ref={register({ required: true })}
                    />
                    <FormFieldError field={errors.postalCode} />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <LabelField>Logradouro</LabelField>
                    <InputField
                      name="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      ref={register({ required: true })}
                    />
                    <FormFieldError field={errors.logradouro} />
                  </Form.Group>
                </Col>
                <Col xs={12} md={3}>
                  <Form.Group>
                    <LabelField>Número</LabelField>
                    <InputField
                      name="number"
                      value={numero}
                      onChange={(e) => setNumero(e.target.value)}
                      ref={register({ required: true })}
                    />
                    <FormFieldError field={errors.numero} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <Form.Group>
                    <LabelField>Cidade</LabelField>
                    <InputField
                      name="city"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      ref={register({ required: true })}
                    />
                    <FormFieldError field={errors.city} />
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group>
                    <LabelField>Bairro</LabelField>
                    <InputField
                      name="neightborhood"
                      value={neightborhood}
                      onChange={(e) => setNeightborhood(e.target.value)}
                      ref={register({ required: true })}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group>
                    <LabelField>Complemento</LabelField>
                    <InputField
                      name="complement"
                      value={complement}
                      onChange={(e) => setComplement(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </fieldset>
            <fieldset>
              <legend>Documentos</legend>
              <Row>
                <Col xs={12} md={12}>
                  {!file1 && (
                    <Form.Group>
                      <Form.File
                        accept=".png,.jpg,.jpeg,.pdf"
                        name="docPic1"
                        id="documentInput1"
                        ref={register({ required: true })}
                        label="Documento de Identificação com CPF (Frente)"
                        custom
                        data-browse="Buscar"
                        onChange={() => {
                          handleDocumentUpload(
                            1,
                            'Documento de Identificação com CPF (Frente)'
                          );
                        }}
                      />
                      <small className="form-text text-muted">
                        Selecione um PDF ou uma imagem
                      </small>
                      <FormFieldError field={errors.docPic1} />
                    </Form.Group>
                  )}
                  {file1 && (
                    <Form.Group style={{borderBottom: '1px solid #e8e7e7'}}>
                      <span style={{ color: '#1fb71f', fontWeight: 'bold' }}>
                        CNH ou RG (Frente) <button style={{
                        fontSize: '10px',
                        padding: '0 10px',
                        lineHeight: '15px',
                        backgroundColor: '#dc3545',
                        color: 'white',
                        border: '0',
                        borderRadius: '15px',
                        marginTop: '3px',
                        display: 'block',
                        float: 'right'}} onClick={() => setFile1(null)}>remover</button>
                      </span>
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  {!file2 && (
                    <Form.Group>
                      <Form.File
                        accept=".png,.jpg,.jpeg,.pdf"
                        name="docPic2"
                        id="documentInput2"
                        ref={register({ required: true })}
                        label="Documento de Identificação com CPF (Verso)"
                        custom
                        data-browse="Buscar"
                        onChange={() => {
                          handleDocumentUpload(
                            2,
                            'Documento de Identificação com CPF (Verso)'
                          );
                        }}
                      />
                      <small className="form-text text-muted">
                        Selecione um PDF ou uma imagem
                      </small>
                      <FormFieldError field={errors.docPic1} />
                    </Form.Group>
                  )}
                  {file2 && (
                    <Form.Group style={{borderBottom: '1px solid #e8e7e7'}}>
                      <span style={{ color: '#1fb71f', fontWeight: 'bold' }}>
                        CNH ou RG (Verso) <button style={{
                        fontSize: '10px',
                        padding: '0 10px',
                        lineHeight: '15px',
                        backgroundColor: '#dc3545',
                        color: 'white',
                        border: '0',
                        borderRadius: '15px',
                        marginTop: '3px',
                        display: 'block',
                        float: 'right'}} onClick={() => setFile2(null)}>remover</button>
                      </span>
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  {!file3 && (
                    <Form.Group>
                      <Form.File
                        accept=".png,.jpg,.jpeg,.pdf"
                        name="docPic3"
                        id="documentInput3"
                        ref={register({ required: true })}
                        label="Comprovante de Residência"
                        custom
                        data-browse="Buscar"
                        onChange={() => {
                          handleDocumentUpload(3, 'Comprovante de Residência');
                        }}
                      />
                      <small className="form-text text-muted">
                        Selecione um PDF ou uma imagem
                      </small>
                      <FormFieldError field={errors.docPic2} />
                    </Form.Group>
                  )}
                  {file3 && (
                    <Form.Group style={{borderBottom: '1px solid #e8e7e7'}}>
                      <span style={{ color: '#1fb71f', fontWeight: 'bold' }}>
                        Comprovante de Residência <button style={{
                        fontSize: '10px',
                        padding: '0 10px',
                        lineHeight: '15px',
                        backgroundColor: '#dc3545',
                        color: 'white',
                        border: '0',
                        borderRadius: '15px',
                        marginTop: '3px',
                        display: 'block',
                        float: 'right'}} onClick={() => setFile2(null)}>remover</button>
                      </span>
                    </Form.Group>
                  )}
                </Col>
              </Row>
            </fieldset>
          </form>
        </WizzardContent>
        <WizzardFooter>
          <StepPreviousBtn onClick={() => changeStep(2)}>
            Voltar
          </StepPreviousBtn>
          <StepNextBtn onClick={handleSubmit(onSubmit)}>
            <StepNextBtnTxt>Salvar e continuar</StepNextBtnTxt>

            <img src={ArrowImage} />
          </StepNextBtn>
          <Modal show={openHelpModal} onHide={() => setOpenHelpModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>O que é o Responsável financeiro?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span style={{ marginBottom: '20px' }}>
                <b>Responsável Financeiro</b>: É quem assina como CONTRATANTE e
                assume as mensalidades, podendo ser beneficiário titular do
                plano ou não. Exemplo: Pai somente como responsável pelo filho
                menor ou Pai como beneficiário titular de um plano familiar.
              </span>
            </Modal.Body>
            <Modal.Footer>
              <span></span>
            </Modal.Footer>
          </Modal>
        </WizzardFooter>
      </CardForm>
    </WizzardBody>
  );
}
