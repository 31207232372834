import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  padding: 16px 0;
  background-color: #fff;
  border-bottom: 1px solid #eddee9;
`;

export const LogoHeader = styled.img`
  width: 215px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const HeaderInforContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  
  a:hover {
    text-decoration: none;
  }
`;

export const NavItem = styled.span`
  color: #810e56;
  font-family: 'Century Gothic Bold', Helvetica, Verdana;
  border: none;
`;
