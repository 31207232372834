import styled from 'styled-components';

export const PlanContainer = styled.article`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #fff;
  border: 1px solid #f1f1f1;
  margin-bottom: 16px;
  border-radius: 6px;
  min-height: 300px;
`;

export const PlanCardHeader = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  padding-bottom: 0.3rem;
  justify-content: space-between;
  border-bottom: 1px dotted #eeeeee;
`;

export const PlanCardHeaderTitle = styled.span`
  flex: 1;
  font-size: 14px;
  font-weight: bold;
  color: #810e56;
`;

export const PlanCardDetailTxt = styled.p`
  font-size: 0.9rem;
  font-family: 'Century Gothic', Helvetica;
  color: #6a7583;
`;

export const PlanCardDetailTxtStrong = styled.strong`
  padding-left: 0.3rem;
  font-family: 'Century Gothic Bold', Helvetica, Verdana;
  text-decoration: underline;
  color: #810e56;
`;

export const PlanCardHeaderValue = styled.span`
  padding: 0.3rem;
  text-align: right;
  font-size: 14px;
  font-weight: bold;
  color: #e28f22;
  background: #f2f2f2;
  border-radius: 3px;
`;

export const PlanCardInfor = styled.p`
  font-size: 12px;
  color: #4e5358;
  line-height: 1.5;
  padding: 8px 0;
`;

export const PlanCardObs = styled.p`
  font-size: 10px;
  color: #6a7583;
`;

export const PlanCardFooter = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PlanCardBtn = styled.button`
  padding: 5px 16px;
  border: none;
  background: #ffbd21;
  color: #fff;
  border-bottom: 3px solid #f7b008;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
