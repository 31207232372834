import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import {
  HeaderContainer,
  LogoHeader,
  HeaderInforContainer,
  NavItem,
} from './styles';

// images
import logo from '../../assets/images/logos/logo-uniodonto.png';

export default function Header() {
  return (
    <HeaderContainer>
      <Container>
        <Row>
          <Col xs={6} md={7}>
            <LogoHeader src={logo} alt="Uniodonto Maceió" />
          </Col>
          <Col xs={6} md={5}>
            <HeaderInforContainer>
              <a
                href="https://www.uniodontomaceio.com.br/uniodonto-para-voce/escolher-plano/"
                target="_blank">
                <NavItem>Simular Plano</NavItem>
              </a>
              <a
                href="https://www4.uniodontomaceio.com.br/portaldobeneficiario/autoatendimento/guiaOdontologico/guia_odontologico_new.php"
                target="_blank">
                <NavItem>Encontre um dentista</NavItem>
              </a>
            </HeaderInforContainer>
          </Col>
        </Row>
      </Container>
    </HeaderContainer>
  );
}
