import React, { useState, useContext, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import { HeaderPage, TitlePage, CardForm, SubTitlePage } from '../styles';

import FisicalContext from '../../../context/fisical';
import SelectedPlanDescription from '../../../widgets/SelectedPlanDescription';

import Step1 from './step1';
import Step2 from './step2';
import FinancialResponsibleStep from './financial_responsible.step';
import DependentsStep from './dependets.step';
import PaymentSelectionStep from './payment_selection.step';
import ReviewStep from './review.step';
import CreditCardStep from './credit-card.step';

export default function Steps() {
    const [isSpecial, setIsSpecial] = useState(false)
  const { step, formData, calcProposalAmount } = useContext(FisicalContext);
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const planId = urlParams.get('planId');

  useEffect(() => {
      if (['64593aa0dd516505bb8614ff', '64dd1f64dd516505bb8615c3', '64ea89abdd516505bb8617d1'].includes(planId)) setIsSpecial(true)
  }, [planId])

  return (
    <>
      <HeaderPage>
        <TitlePage>Preenchimento da proposta de adesão</TitlePage>
        <SubTitlePage>Insira suas informações para a proposta</SubTitlePage>
      </HeaderPage>
      <Row>
        {formData.status === 'Pendente' && (
          <Col xs={12} md={8}>
            <ReviewStep />
          </Col>
        )}
        {formData.status !== 'Pendente' && (
          <Col xs={12} md={8}>
            {step === 1 && <Step1 isSpecial={isSpecial} />}
            {step === 2 && <Step2 isSpecial={isSpecial} />}
            {step === 3 && <FinancialResponsibleStep />}
            {step === 4 && <DependentsStep isSpecial={isSpecial} />}
            {step === 5 && <PaymentSelectionStep />}
            {step === 6 && <ReviewStep isSpecial={isSpecial} />}
            {step === 7 && <CreditCardStep />}
          </Col>
        )}
        <Col xs={12} md={4}>
          <SelectedPlanDescription
            proposal={formData}
            planId={planId}
            calcProposalAmount={calcProposalAmount}
          />
        </Col>
      </Row>
    </>
  );
}
