import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Row, Col, Modal, Alert } from 'react-bootstrap';
import PDFViewer from 'mgr-pdf-viewer-react';
import api from '../../../services/api';
import DocTermoAdesao from '../../../assets/docs/TERMO_DE_CONVENIO_PREFEITURA_DE_MACEIO.pdf';
import ReviewLabel from '../../../widgets/ReviewLabel';
import ArrowImage from '../../../assets/icons/arrow.svg';
import {
  WizzardBody,
  WizzardHeader,
  WizzardContent,
  CardForm,
  WizzardFooter,
  LabelField,
  InputField,
  Legend,
  StepNextBtn,
  StepNextBtnTxt,
  StepPreviousBtn,
  TermContainer, PdfContainer,
} from '../styles';
import FisicalContext from '../../../context/fisical';

const planos_da_prefeitura = ['64593aa0dd516505bb8614ff','64ea89abdd516505bb8617d1','64dd1f64dd516505bb8615c3']

export default function ReviewStep({isSpecial}) {
  const [plan, setPlan] = useState(null);
  const [highlightTerms, setHighlightTerms] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [resposnseUrl, setResposnseUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  const { search } = useLocation();
  const {
    changeStep,
    formData,
    updateData,
    finishProposal,
    getFormDataWithAmount,
  } = useContext(FisicalContext);

  const urlParams = new URLSearchParams(search);
  const planId = urlParams.get('planId');

  const idsSemPagamento = [
    '642c227add516505bb8614d3'
  ]

  /**
   * @description Get a plan by id
   * @param id:string
   * */
  function getPlanByID() {
    api.get(`/plans/plan/${planId}`).then((res) => {
      setPlan(res.data);
    });
  }

  function getContract(code) {
    return `https://vendas-admin.uniodontomaceio.com.br/vendas-admin/documents/contrato_${code}.pdf`;
  }

  function getTermoAdesao(code) {
    return ;
  }

  function getManual(code) {
    return `https://vendas-admin.uniodontomaceio.com.br/vendas-admin/documents/manual_contrat_${code}.pdf`;
  }

  function getManualToRead(code) {
    return `https://vendas-admin.uniodontomaceio.com.br/vendas-admin/documents/guia_leitura_${code}.pdf`;
  }

  function formatDate(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return (
      date.getDate() +
      '/' +
      (date.getMonth() + 1) +
      '/' +
      date.getFullYear() +
      '  ' +
      strTime
    );
  }

  function finish() {
    if (!agreed && !planos_da_prefeitura.includes(planId)) {
      setHighlightTerms(true);
      setTimeout(() => setHighlightTerms(false), 4000);
      return;
    }
    if (!agreed && !planos_da_prefeitura.includes(planId) && isSpecial) {
      setHighlightTerms(true);
      setTimeout(() => setHighlightTerms(false), 4000);
      return;
    }
    if (!agreed && planos_da_prefeitura.includes(planId)) {
      setHighlightTerms(true);
      setTimeout(() => setHighlightTerms(false), 4000);
      return;
    }
    const data = getFormDataWithAmount();
    finishProposal()
      .then((response) => {
        api
          .post(
            `https://www4.uniodontomaceio.com.br/vendor/pagseguro/pagseguro-php-sdk/public/PreApproval/createPreApproval.php`,
            { ...data }
          )
          .then((res) => {
            if (formData.plan.company === 'GENERICO') {
              window.location.replace(res.data);
              return
            }
            setShowModal(true);
            startCountdown(response.data, res.data);
          })
          .catch((error) => {
            console.error(error)
          });
      })
      .catch((error) => {
        console.error(error)
      });
  }

  const startCountdown = (data, url) => {
    let count = 5
    setCountdown(count)
    setInterval(() => {
      count = (count - 1)
      setCountdown(count)
      if (count === 1) {
        updateData({ ...data, paymentUrl: url, acceptedDate: formatDate(new Date()), });
      }
      if (count === 0) {
        window.location.replace(url);
      }
    }, 1000);
  }

  useEffect(() => {
    getPlanByID();
    window.scrollTo(0, 0);
  }, []);

  return (
    <WizzardBody>
      <CardForm>
        <WizzardHeader>
          <h6>Resumo da Proposta</h6>
        </WizzardHeader>
        <WizzardContent>
          {formData.isFinantialResponsible === 'Sim' && (
            <Legend>Titular da Proposta</Legend>
          )}
          {formData.isFinantialResponsible === 'Não' && (
            <Legend>Titular da Proposta / Responsável Financeiro</Legend>
          )}
          <Row>
            <ReviewLabel label="Nome" info={formData.customer.name} />
            {isSpecial && <ReviewLabel label="Matrícula" info={formData.customer.matricula} />}
          </Row>
          <Row>
            <ReviewLabel label="Email" info={formData.customer.email} />
          </Row>
          <Row>
            <ReviewLabel label="Telefone" info={formData.customer.phone} />
            <ReviewLabel label="Nascimento" info={formData.customer.born} />
            <ReviewLabel
              label="Estado civil"
              info={formData.customer.civilState}
            />
          </Row>
          <Row>
            <ReviewLabel
              label="Nome da mãe"
              info={formData.customer.parentName}
            />
            <ReviewLabel label="CPF" info={formData.customer.social_number} />
            <ReviewLabel label="Sexo" info={formData.customer.sex} />
          </Row>
          <Row>
            <ReviewLabel label="CEP" info={formData.customer.postalCode} />
            <ReviewLabel label="Logradouro" info={formData.customer.address} />
            <ReviewLabel
              label="Bairro"
              info={formData.customer.neightborhood}
            />
          </Row>
          <Row>
            <ReviewLabel label="Número" info={formData.customer.number} />
            <ReviewLabel label="Cidade" info={formData.customer.city} />
            <ReviewLabel
              label="Complemento"
              info={formData.customer.complement}
            />
          </Row>
          {formData.isFinantialResponsible === 'Não' && (
            <>
              <br />
              <Legend>Responsável Financeiro</Legend>
              <Row>
                <ReviewLabel
                  label="Nome"
                  info={formData.finantialResponsible.name}
                />
              </Row>
              <Row>
                <ReviewLabel
                  label="Email"
                  info={formData.finantialResponsible.email}
                />
              </Row>
              <Row>
                <ReviewLabel
                  label="Telefone"
                  info={formData.finantialResponsible.phone}
                />
                <ReviewLabel
                  label="Nascimento"
                  info={formData.finantialResponsible.born}
                />
                <ReviewLabel
                  label="Estado civil"
                  info={formData.finantialResponsible.civilState}
                />
              </Row>
              <Row>
                <ReviewLabel
                  label="CPF"
                  info={formData.finantialResponsible.social_number}
                />
                <ReviewLabel
                  label="Sexo"
                  info={formData.finantialResponsible.sex}
                />
                <ReviewLabel
                  label="Nome da mãe"
                  info={formData.finantialResponsible.parentName}
                />
              </Row>
              <Row>
                <ReviewLabel
                  label="CEP"
                  info={formData.finantialResponsible.postalCode}
                />
                <ReviewLabel
                  label="Logradouro"
                  info={formData.finantialResponsible.address}
                />
                <ReviewLabel
                  label="Bairro"
                  info={formData.finantialResponsible.neightborhood}
                />
              </Row>
              <Row>
                <ReviewLabel
                  label="Número"
                  info={formData.finantialResponsible.number}
                />
                <ReviewLabel
                  label="Cidade"
                  info={formData.finantialResponsible.city}
                />
                <ReviewLabel
                  label="Complemento"
                  info={formData.finantialResponsible.complement}
                />
              </Row>
            </>
          )}
        </WizzardContent>
        {formData.dependents.map((dep, index) => {
          return (
            <WizzardContent key={index}>
              <br />
              <Legend>Dependente {index + 1}</Legend>
              <Row>
                <ReviewLabel label="Nome" info={dep.name} />
                <ReviewLabel label="Nascimento" info={dep.born} />
              </Row>
              <Row>
                <ReviewLabel label="CPF" info={dep.social_number} />
                <ReviewLabel label="Sexo" info={dep.sex} />
              </Row>
              <Row>
                <ReviewLabel label="Estado civil" info={dep.civilState} />
                <ReviewLabel label="Nome da mãe" info={dep.parentName} />
              </Row>
              <Row>
                <ReviewLabel label="Filiação" info={dep.type} />
                <ReviewLabel label="" info="" />
              </Row>
            </WizzardContent>
          );
        })}
        {formData.plan.company !== 'GENERICO' && (
          <WizzardContent>
            <br />
            <Legend>Forma de Pagamento</Legend>
            <Row>
              <ReviewLabel label="" info={formData.recurrency} />
            </Row>
          </WizzardContent>
        )}
        {formData.status !== 'Pendente' && planos_da_prefeitura.includes(planId) && (
            <>
              <hr/>
            <WizzardContent>
            <Row>
              <Legend style={{padding: '16px'}}>
              Contrato - Necessário ler e aceitar os termos do contrato para posseguir.
                </Legend>
              </Row>
            </WizzardContent>
              <PdfContainer>
                <PDFViewer document={{
                  url: DocTermoAdesao
                }} />
              </PdfContainer>
              <WizzardContent>
                <Row>
                  <Legend style={{padding: '16px'}}>
                    <a style={{}} href={DocTermoAdesao} target="_blank">
                      Baixar termo de convênio
                    </a>
                  </Legend>
                </Row>
              </WizzardContent>

              <WizzardContent>
                <Row>
                  <Legend style={{padding: '16px'}}>
                    TERMO DE ADESÃO AO PLANO DE SAÚDE ODONTOLÓGICO CELEBRADO ENTRE O MUNICÍPIO DE MACEIÓ, ATRAVÉS DA SECRETARIA MUNICIPAL DE GESTÃO - SEMGE E A UNIODONTO MACEIÓ – COOPERATIVA ODONTOLÓGICA (Convênio nº: 02/2023 - Processo Administrativo nº: 2100.110973/2022).
                  </Legend>
                </Row>
              </WizzardContent>
              <TermContainer>
                <label htmlFor="" className={highlightTerms ? 'label-error' : ''}>
                  <input
                      type="checkbox"
                      name=""
                      id=""
                      value={agreed}
                      onClick={() => setAgreed(!agreed)}
                  />
                  Por este Termo de Adesão, Eu, <b>{formData.customer.name}</b>, na qualidade de Servidor(a) da Prefeitura Municipal de Maceió, matrícula nº <b>{formData.customer.matricula}</b>, solicito a minha inclusão, bem como as de meus dependentes (se houver), no Plano de Saúde Odontológico, tendo em vista o Convênio celebrado entre o município de Maceió, através da Secretaria Municipal de Gestão (Semge) e a Uniodonto Maceió – Cooperativa Odontológica.
                </label>
              </TermContainer>
            </>
        )}
        {formData.status !== 'Pendente' && !planos_da_prefeitura.includes(planId) && (
          <TermContainer>
            <label htmlFor="" className={highlightTerms ? 'label-error' : ''}>
              <input
                type="checkbox"
                name=""
                id=""
                value={agreed}
                onClick={() => setAgreed(!agreed)}
              />
              Declaro que li e concordo integralmente com:{' '}
              <a href={plan && getContract(plan.code)} target="_blank">
                Contrato
              </a>
              ,{' '}
              <a href={plan && getManual(plan.code)} target="_blank">
                Manual de contratação
              </a>{' '}
              e{' '}
              <a href={plan && getManualToRead(plan.code)} target="_blank">
                Manual de leitura
              </a>
              .
            </label>
          </TermContainer>
        )}
        <WizzardFooter>
          { formData.status !== 'Pendente' &&
            <StepPreviousBtn onClick={() => {
              if (formData.plan.company === 'GENERICO' || idsSemPagamento.includes(planId)) {
                changeStep(4);
                return
              }
              changeStep(5);
            }}>
              Voltar
            </StepPreviousBtn>
          }
          {formData.status !== 'Pendente' && (
            <StepNextBtn onClick={() => finish()}>
              <StepNextBtnTxt>Finalizar {formData.plan.company === 'GENERICO' ? '' : 'e Pagar'}</StepNextBtnTxt>
              <img src={ArrowImage} />
            </StepNextBtn>
          )}
        </WizzardFooter>
      </CardForm>
      <Modal show={showModal}>
        <Modal.Header closeButton>
          <Modal.Title>Pagamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success">Sua proposta foi enviada com sucesso</Alert>
          <span>
            Em {countdown} segundos você será redirecionado para realizar o
            pagamento.
          </span>
        </Modal.Body>
        <Modal.Footer>
          {/* <button variant="secondary" onClick={() => setShowModal(false)}>
            Sair
          </button> */}
          <img
            src="https://www.sinalplast.com.br/wp-content/uploads/2014/12/Logos-Cart%C3%B5es-de-Cr%C3%A9dito-com-Logo-Pagseguro.jpg"
            alt=""
            height="110px"
          />
        </Modal.Footer>
      </Modal>
    </WizzardBody>
  );
}
