import React, {useState, createContext, useEffect} from 'react';
import api, {notifyProposal} from "../services/api";
import {useLocation} from "react-router-dom";

const FisicalContext = createContext({ atualStep: 1 });

export const FisicalProvider = ({ children }) => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    finantialResponsible: {
      name: null,
      civilState: null,
      born: null,
      phone: null,
      social_number: null,
      email: null,
      sex: null,
      postalCode: null,
      address: null,
      neightborhood: null,
      number: null,
      complement: null,
      city: null,
      parentName: null,
    },
    isFinantialResponsible: 'Sim',
    customer: {
      name: null,
      civilState: null,
      born: null,
      phone: null,
      social_number: null,
      email: null,
      sex: null,
      postalCode: null,
      address: null,
      neightborhood: null,
      number: null,
      complement: null,
      city: null,
      parentName: null,
    },
    dependents: [],
    plan: null,
    paymentType: null,
    recurrency: null,
    paymentUrl: null,
    accountBank: null,
    accountNumber: null,
    accountNumberDigit: null,
    accountAgency: null,
    accountAgencyDigit: null,
    sellerCode: null,
    status: 'Rascunho',
    origin: null,
    campaign: null,
    documents: [],
  });

  function changeStep(newStep) {
    setStep(newStep);
  }

  function updateData(obj) {
    const info = {...formData, ...obj}
    setFormData(info);
    saveProposal(info);
  }

  function updateServerData(obj) {
    console.log(obj)
    const info = {...formData, ...obj}
    setFormData({...info});
  }

  useEffect(() => console.log(formData), [formData])

  function formatDate(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return (
      date.getDate() +
      '/' +
      (date.getMonth() + 1) +
      '/' +
      date.getFullYear() +
      '  ' +
      strTime
    );
  }

  function finishProposal() {
    let data = {...formData}
    if (data.status === 'Pendente' || data.status === 'Aprovada' || data.status === 'Conluída') {
      return;
    }
    const totalAmount = calcProposalAmount(data);
    data = {
      ...data,
      amount: totalAmount,
      acceptedDate: formatDate(new Date()),
    };
    data = adjustMaskInputs(data);
    return api.put('/proposals', {
      ...data,
      amount: totalAmount,
      status: 'Pendente'
    });
  }

  function getFormDataWithAmount() {
    return {...formData, amount: calcProposalAmount(formData)}
  }

  function saveProposal(data) {
    if (data.status !== 'Aprovada' && data.status !== 'Conluída') {
      notifyProposal(data).then().catch();
    }
    if (data.status === 'Pendente' || data.status === 'Aprovada' || data.status === 'Conluída') {
      return;
    }
    const totalAmount = calcProposalAmount(data);
    data = {
      ...data,
      amount: totalAmount,
    };
    data = adjustMaskInputs(data);
    if (data['_id']) {
      api.put('/proposals', {
        ...data,
        amount: totalAmount,
      });
    } else {
      api
        .post('/proposals', {
          ...data,
          amount: totalAmount,
        })
        .then((response) => {
          setFormData(response.data);
        });
    }
  }

  const calcProposalAmount = (data) => {
    let amount = 0;
    if (data.plan) {
      if (data.recurrency === 'Mensal') {
        if (
          data.plan &&
          (!data.dependents || data.dependents.length === 0)
        ) {
          amount = parseFloat(data.plan.amount_1_life);
        } else if (
          data.plan &&
          data.dependents &&
          data.dependents.length === 1
        ) {
          amount = parseFloat(data.plan.amount_2_life) * 2;
        } else if (
          data.plan &&
          data.dependents &&
          data.dependents.length === 2
        ) {
          amount = parseFloat(data.plan.amount_3_life) * 3;
        } else if (
          data.plan &&
          data.dependents &&
          data.dependents.length === 3
        ) {
          amount = parseFloat(data.plan.amount_4_life) * 4;
        } else if (
          data.plan &&
          data.dependents &&
          data.dependents.length > 3
        ) {
          amount =
            parseFloat(data.plan.amount_4_life) *
            (data.dependents.length + 1);
        }
      } else if (data.recurrency === 'Anual') {
        if (
          data.plan &&
          (!data.dependents || data.dependents.length === 0)
        ) {
          amount = parseFloat(data.plan.amount_1_life) * 12;
        } else if (
          data.plan &&
          data.dependents &&
          data.dependents.length === 1
        ) {
          amount = parseFloat(data.plan.amount_2_life) * 2 * 12;
        } else if (
          data.plan &&
          data.dependents &&
          data.dependents.length === 2
        ) {
          amount = parseFloat(data.plan.amount_3_life) * 3 * 12;
        } else if (
          data.plan &&
          data.dependents &&
          data.dependents.length === 3
        ) {
          amount = parseFloat(data.plan.amount_4_life) * 4 * 12;
        } else if (
          data.plan &&
          data.dependents &&
          data.dependents.length > 3
        ) {
          amount =
            parseFloat(data.plan.amount_4_life) *
            (data.dependents.length + 1) *
            12;
        }
      }
    }
    return amount;
  };

  const adjustMaskInputs = (values) => {
    if (values.customer && values.customer.social_number) {
      values.customer.social_number =
        ('customer.social_number',
          values.customer.social_number.replace('-', '').replace('.', ''));
    }
    if (values.customer && values.customer.phone) {
      values.customer.phone =
        ('customer.phone',
          values.customer.phone
            .replace(' ', '')
            .replace('-', '')
            .replace('(', '')
            .replace(')', ''));
    }
    if (values.isFinantialResponsible !== 'Sim') {
      if (values.finantialResponsible) {
        if (values.finantialResponsible.social_number) {
          values.finantialResponsible.social_number =
            ('customer.social_number',
              values.finantialResponsible.social_number
                .replace('-', '')
                .replace('.', ''));
        }
        if (values.finantialResponsible.phone) {
          values.finantialResponsible.phone =
            ('customer.phone',
              values.finantialResponsible.phone
                .replace(' ', '')
                .replace('-', '')
                .replace('(', '')
                .replace(')', ''));
        }
      }
    }
    if (values.dependents && values.dependents.length > 0) {
      for (let i = 0, ilen = values.dependents.length; i < ilen; i++) {
        if (values.dependents[i].social_number) {
          values.dependents[i].social_number = values.dependents[
            i
            ].social_number
            .replace('-', '')
            .replace('.', '');
        }
      }
    }
    return values;
  };

  return (
    <FisicalContext.Provider value={{ step, formData, changeStep, updateData, calcProposalAmount, finishProposal, updateServerData, getFormDataWithAmount }}>
      {children}
    </FisicalContext.Provider>
  );
};

export default FisicalContext;
